import { PaginationLink } from '../types'

export const parsePaginationLink = (props: {
  fields: {
    slug: string
  }
  frontmatter: {
    title: string
  }
}): PaginationLink => ({
  href: props.fields.slug,
  title: props.frontmatter.title,
})
