import classNames from 'classnames'
import React from 'react'

import { Props } from './types'

export const Content: React.FC<Props> = ({ content, section, title }) => (
  <>
    <div className={classNames('mb-2 text-sm')}>{section}</div>
    <h1 className={classNames('font-bold', 'leading-none', 'mb-6', 'text-5xl')}>
      {title}
    </h1>
    <div className="prose" dangerouslySetInnerHTML={{ __html: content }} />
  </>
)

Content.displayName = 'Content'
