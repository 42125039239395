import classNames from 'classnames'
import { graphql } from 'gatsby'
import React from 'react'

import { Container } from 'components/Container'
import { Content } from 'components/Content'
import { Navigation } from 'components/Navigation'
import { Minimap } from 'components/Minimap'
import { Pagination } from 'components/Pagination'
import { parsePaginationLink } from 'components/Pagination/utils'

import { Props } from './types'

const Page: React.FC<Props> = ({
  data: {
    markdownRemark: {
      frontmatter: { section, title },
      html,
      rawMarkdownBody,
    },
    next,
    previous,
  },
}) => (
  <Container>
    <div className={classNames('flex', 'flex-wrap', '-mx-4')}>
      <div
        className={classNames('hidden', 'px-4', 'md:block', 'w-1/6')}
      >
        <Navigation />
      </div>
      <div
        className={classNames('lg:w-2/3', 'px-4', 'md:w-5/6', 'w-full')}
      >
        <Content content={html} section={section} title={title} />
        <Pagination
          links={[parsePaginationLink(next), parsePaginationLink(previous)]}
        />
      </div>

      <div
        className={classNames('hidden', 'lg:block', 'px-4', 'w-1/6')}
      >
        <Minimap markdown={rawMarkdownBody} />
      </div>
    </div>
  </Container>
)

/**
 * The `Page` component needs to be exported by default, in order for the
 * script in `gatsby-node.js` to resolve it
 */
export default Page

export const PageQuery = graphql`
  query ($id: String!, $previousId: String, $nextId: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        section
        title
      }
      html
      rawMarkdownBody
    }
    previous: markdownRemark(id: { eq: $previousId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
