import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { Props } from './types'
import { createAnchorLink } from './utils'

export const TitleLink: React.FC<Props> = ({ children, classes }) => (
  <Link to={createAnchorLink(children)} className={classNames(...classes)}>
    {children}
  </Link>
)

TitleLink.displayName = 'TitleLink'
