import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { useSitemap } from 'hooks/useSitemap'

export const Navigation: React.FC = () => {
  /**
   * Use custom hook to get sitemap structure
   */
  const sitemap = useSitemap()
  /**
   * Return navigation component
   */
  return (
    <nav className={classNames('sticky top-9')}>
      {Object.entries(sitemap).map(([section, pages]) => (
        <div className={classNames('mb-9')} key={section}>
          <h3 className={classNames('font-semibold', 'mb-4')}>{section}</h3>
          <ul>
            {pages.map(({ href, label }) => (
              <li className={classNames('mb-2', 'text-sm')} key={href}>
                <Link activeClassName="text-teal" to={href}>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </nav>
  )
}

Navigation.displayName = 'Navigation'
