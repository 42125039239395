import classNames from 'classnames'
import { Link } from 'gatsby'
import React from 'react'

import { Props } from './types'

export const Pagination: React.FC<Props> = ({ links }) => {
  /**
   * Destruct previous and next links
   */
  const [next, previous] = links

  return (
    <div className={classNames('max-w-prose', 'mt-16')}>
      <hr className={classNames('border-gray-200', 'my-16')} />
      <div className={classNames('flex', 'justify-between')}>
        <Link className={classNames('hover:text-teal')} to={previous.href}>
          <span aria-hidden="true" className="mr-2">
            ←
          </span>
          <span className={classNames('font-semibold')}>{previous.title}</span>
        </Link>
        <Link className={classNames('hover:text-teal')} to={next.href}>
          <span className={classNames('font-semibold')}>{next.title}</span>
          <span aria-hidden="true" className="ml-2">
            →
          </span>
        </Link>
      </div>
    </div>
  )
}

Pagination.displayName = 'Pagination'
