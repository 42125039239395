import classNames from 'classnames'
import React from 'react'
import ReactMarkdown from 'react-markdown'

import { Dummy } from './components/Dummy'
import { TitleLink } from './components/TitleLink'
import { Props } from './types'

export const Minimap: React.FC<Props> = ({ markdown }) => (
  <div className={classNames('sticky top-9')}>
    <h5 className={classNames('font-semibold', 'mb-4')}>Overview</h5>
    <ReactMarkdown
      components={{
        a: Dummy,
        blockquote: Dummy,
        h1: Dummy,
        h2: ({ children }) => (
          <TitleLink classes={['block', 'mb-2', 'text-sm']}>
            {children}
          </TitleLink>
        ),
        h3: ({ children }) => (
          <TitleLink classes={['block', 'ml-4', 'mb-2', 'text-sm']}>
            {children}
          </TitleLink>
        ),
        h4: Dummy,
        hr: Dummy,
        ol: Dummy,
        p: Dummy,
        pre: Dummy,
      }}
      skipHtml
    >
      {markdown}
    </ReactMarkdown>
  </div>
)

Minimap.displayName = 'Minimap'
